<template>
	<div>
		<div class="explain-action">
			
			<div class="exp-ac-item">
				<el-switch v-model="showAllPlace" :active-text="$t('i18nn_a7162d05268c0fe9')"
					:inactive-text="$t('i18nn_22905878d9b15acc')" active-value="1" inactive-value="2" @change="changeShowAllPlace">
				</el-switch>
			</div>
			<div class="exp-ac-item">
				<el-switch v-model="showPlaceName" active-color="#13ce66" inactive-color="#ff4949" :active-text="$t('i18nn_28b7dbfbaf281deb')"
					:inactive-text="$t('i18nn_4415614666ad24cb')" active-value="1" inactive-value="2">
				</el-switch>
			</div>
		</div>
		<div class="explain-tip">
			<div class="tip-chart">
				<span>{{$t('i18nn_62f1c7e4748e6c28')}}</span>
				<div class="b-place area-style"></div>
			</div>
			<div class="tip-chart">
				<span>{{$t('i18nn_8f9184cef1644784')}}</span>
				<div class="">
					<el-tag size="mini">
						000
					</el-tag>
				</div>
			</div>
			<div class="tip-chart">
				<span>{{$t('i18nn_a5606fce0b131a1b')}}</span>
				<div class="">
					<el-tag type="warning" size="mini">
						000
					</el-tag>
				</div>
			</div>
			<div class="tip-chart">
				<span>{{$t('i18nn_b4c5f9946aeeaf70')}}</span>
				<div class="">
					<el-tag type="success" size="mini">
						000
					</el-tag>
				</div>
			</div>
			<div class="tip-chart">
				<span>{{$t('i18nn_4e0d087d88156795')}}</span>
				<div class="b-place pType-1"></div>
			</div>
			<div class="tip-chart">
				<span>{{$t('i18nn_750722ca673dfa57')}}</span>
				<div class="b-place pType-2"></div>
			</div>
			
		</div>
		<div style="font-size: 14px; font-weight: bold; padding: 5px 10px;">
			{{chartData.title}}
		</div>
		<div class="b-area-con">
			<div class="b-area area-style" v-for="(item,index) in chartData.Data" :key="index">
				<div class="b-area-header">
					<div class="b-area-name">
						<span>{{item.name}}</span>
						<el-tag size="">{{item.children.length}}</el-tag>
						<el-tag type="warning">{{item.placeHasNum}}</el-tag>
						<el-tag type="success">{{item.allNum}}</el-tag>
					</div>
					<div class="b-h-action">
						<el-button icon="el-icon-arrow-down" size="mini" v-if="'1'==item.showPlace" @click="togglePlace('2',item,index)">{{$t('i18nn_d79e2ed6a2fc66fd')}}</el-button>
						<el-button icon="el-icon-arrow-up" size="mini" v-else-if="'2'==item.showPlace" @click="togglePlace('1',item,index)">{{$t('i18nn_b978a8259ecfca8f')}}</el-button>
					</div>
				</div>
				
				<div class="b-place-con" v-show="'2'==item.showPlace">
					<div class="b-place" @click="showPlaceDet(item2,item)"
						:class="{'pType-1':'1'==item2.placeType,'pType-2':'2'==item2.placeType}"
						v-for="(item2,index2) in item.children" :key="index2">
						<span v-if="'1'==showPlaceName">{{item2.placeStockTotal}}</span>
						<span v-else-if="'2'==showPlaceName" :class="{'placeName-Type':!!item2.placeStockTotal && 0!=item2.placeStockTotal}">{{item2.name}}</span>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	export default {
		props: {
			openTime: {},
			parm: {
				default: function() {
					return {};
				},
				type: Object
			},
			chartData: {
				default: function() {
					return {
						title: '',
						Data: [],
					};
				},
				type: Object
			}
		},
		components: {},
		data() {
			return {
				loading: false,
				showPlaceName: '1', //1-->件数，2-->库位
				showAllPlace:'1',//1--隐藏，2--显示
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
			}
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {

		},
		methods: {
			initData() {
				this.chartData.Data.forEach((item)=>{
					item.showPlace = '1';
				});
				// console.log('this.chartData.Data',this.chartData.Data);
				this.showPlaceName = "1";
				this.showAllPlace = "1";
				this.$forceUpdate();
			},
			togglePlace(v,item,index){
				item.showPlace = v;
				this.$forceUpdate();
			},
			showPlaceDet(item2, item) {
				this.$alert(`<ul>
					<li>区域：${item.name}</li>
					<li>库位：${item2.name}</li>
					<li>库位类型：${1==item2.placeType?this.$t('i18nn_4e0d087d88156795'):''}${2==item2.placeType?this.$t('i18nn_750722ca673dfa57'):''}</li>
					<li>件数：${item2.placeStockTotal}</li>
				</ul>`, this.$t('i18nn_3def3d09b6b0b42a'), {
					dangerouslyUseHTMLString: true
				});
			},
			changeShowAllPlace(v){
				console.log('changeShowAllPlace',v);
				this.chartData.Data.forEach((item)=>{
					item.showPlace = v;
				})
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.explain-action {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		.exp-ac-item {
			margin:0 10px;
		}
	}
	.explain-tip {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		.tip-chart {
			margin: 5px;
		}
	}

	.b-area-con {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		// &:after {
		// 	content: ".";
		// 	display: block;
		// 	height: 0;
		// 	clear: both;
		// 	visibility: hidden;
		// }
	}

	.b-area {
		// max-width: 400px;
		min-width: 180px;
		margin: 5px;
		padding: 5px;
		

		// float: left;
		// 
		.b-area-header {
			display: flex;
			align-items: center;
			// justify-content: space-around;
			.b-area-name {
				// width: 100%;
				font-size: 30px;
				line-height: 120%;
				font-weight:bold;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.b-h-action {
				
			}
		}
		

		.b-place-con {
			display: flex;
			flex-wrap: wrap;


		}
	}

	.b-place {
		border: 1px solid #ccc;
		margin: 1px;
		padding: 1px;
		width: 50px;
		height: 14px;
		font-size: 12px;
		line-height: 14px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		cursor: pointer;

		&:hover {
			border-color: #333;
			font-weight: bold;
		}
	}
	.area-style {
		background-color: #F2F6FC;
		border: 1px solid #ccc;
	}
	.pType-1 {
		background-color: rgb(253, 246, 236);
	}

	.pType-2 {
		background-color: rgb(233, 233, 235);
	}
	.placeName-Type {
		// color:#67c23a;
		font-weight:bold;
	}
</style>