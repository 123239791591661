<template>
	<div class="">
		<div class="tableCon" v-loading="loading_load" :element-loading-text="$t('47df8be257c59dde')">
			<div class="tableConTable">
				<div class="tableConTop">
					<el-row>
						<el-col :span="5" class="tableConTopLeft">
							<h3>
								<span class="tct_tit">{{$t('i18nn_f3f39bae61e74a8c')}}</span>

							</h3>
						</el-col>
						<el-col :span="19" class="tableConTopRig">

							<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
							</el-button>
						</el-col>
					</el-row>
				</div>

				<div>
					<div class="filterCon">
						<!-- <el-row type="flex" justify="end"> -->
						<!-- <el-col :span="24"> -->
						<ul class="filterConList">

							<li>
								<span class="red-required">{{ $t('c944a6686d996ab3') }}</span>
								<!-- {{whNo}} -->
								<el-select size="small" filterable clearable v-model="filterData.whNo"
									:placeholder="$t('2ad108ab2c560530')" style="width: 200px;" @change="initData()">
									<el-option v-for="item in selectOption.wh_no" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')"
										:value="item.code">
										<div class="sel_option_s1" style="">
											<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
											<span class="sel_option_code" style="">{{ item.code }}</span>
										</div>
									</el-option>
								</el-select>

							</li>
							<li>
								<span>{{ $t('hytxs0000036') }}</span>
								<el-select size="small" filterable clearable v-model="filterData.wh_place_type"
									:placeholder="$t('2ad108ab2c560530')" style="width: 200px;" @change="initData()">
									<el-option v-for="item in selectOption.wh_place_type" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')"
										:value="item.code">
										<div class="sel_option_s1" style="">
											<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
											<span class="sel_option_code" style="">{{ item.code }}</span>
										</div>
									</el-option>
								</el-select>
							</li>

							<li>
								<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
								<el-input type="text" v-model="filterData.keyword" size="small" clearable
									@keyup.enter.native="initData()" maxlength="50" :placeholder="$t('custom_enterfuzzysearch')"
									style="width: 180px;" />
							</li>

							<li>
								<el-button icon="el-icon-search" size="small" type="primary" @click="initData()">
									{{ $t('1e7246dd6ccc5539') }}</el-button>
							</li>
						</ul>
						<!-- </el-col> -->
						<!-- </el-row> -->
					</div>
				</div>

				<div>
					<ChartsWhPlace :openTime="ChartsOpenTime" :chartData="{
								title: filterData.whNo,
								Data: treeData,
							}"></ChartsWhPlace>
					<!-- <ThreeWhPlace :openTime="ChartsOpenTime" :chartData="{
								title: filterData.whNo,
								Data: treeData,
							}"></ThreeWhPlace> -->
				</div>

				<!-- <el-table id="ex_table" ref="multipleTable" :data="tableData" stripe :border="true"
					:height="$store.state.tableMaxHeight4" @row-click="handleCurrentChange"
					@selection-change="handleSelectionChange" style="width: 100%" size="small">
					<el-table-column  type="index" width="50" align="center"
						:label="$t('7b1c2b1adc920d9c')"></el-table-column>

					<el-table-column prop="whNo" :label="$t('5acbec83efb27445')" width="200">
						
					</el-table-column>

					<el-table-column prop="pArea" :label="$t('hytxs0000034')">
						
					</el-table-column>
					<el-table-column prop="place" :label="$t('hytxs0000035')">
						
					</el-table-column>

					<el-table-column prop="placeType" :label="$t('hytxs0000036')" width="200">
						
					</el-table-column>

					<el-table-column prop="totalStock" :label="$t('hytxs0000037')">
						
					</el-table-column>


					<el-table-column prop="remark" :label="$t('15b3627faddccb1d')" show-overflow-tooltip>
						
					</el-table-column>

				</el-table> -->
			</div>
			<div class="tableConPagination">
				<span>{{$t('73bbf51487ec69e9')}}</span>{{pagination.total}}<span>{{$t('hytxs0000035')}}</span>
			</div>
		</div>

	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	
	import ChartsWhPlace from '@/components/WarehouseCenter2/SysConfig/components/ChartsWhPlace.vue';
	// import ThreeWhPlace from '@/components/WarehouseCenter2/SysConfig/components/ThreeWhPlace.vue';

	export default {
		// name: 'HomeConfFrame',
		//meta信息seo用
		// metaInfo: {
		// 	title: 'HYTX-WMS-ADMIN'
		// },
		components: {
			ChartsWhPlace
			// ThreeWhPlace
		},
		props: {

		},
		data() {
			return {
				//excel导出
				// excelOpenTime: '',
				// excelHead: [],
				// expExcelData: [],
				// excelName: '',
				// excelOption: {},

				// importOpentTime: '',

				//打开入库选择框
				// dialogSelVisible: false,
				// dialogVisible2: false,
				// loading_pdf: false,
				// PdfData: [],
				ChartsOpenTime: '',
				//选择
				currentSel: {},
				//表格数据
				//loading,表格数据
				loading: false,
				loading_load: false,
				//表格数据
				tableData: [],
				//缓存，原数据
				// tableDataCatch: [],
				treeData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				//表格选择的数据
				multipleSelection: [],
				//查询，排序方式
				//"orderBy": "auditTime,fCon,goodsCon", //排序字段
				selectOption: {
					wh_no: [],
					wh_place_type: []
				},

				filterData: {
					orderBy: 'place_type', //排序字段
					sortAsc: 'N', //desc降序，asc升序

					whNo: '',
					keywords: '',
					pArea: '', //this.$t('hytxs0000034'),
					place: '', //this.$t('hytxs0000035'),
					keyword: '',
					wh_place_type: ''
					// plStatue: '',
					// plNo: '',
					// putWhNo: '',
					// goodsSku: '',
					// goodsName: ''
				}
			};
		},
		watch: {
			// whNo(to, from) {
			// 	console.log('whNo',this.whNo);
			// 	this.filterData.whNo = this.whNo;
			// 	this.initData();
			// }
			// whNo: function(newVal, oldVal) {
			// 	console.log('whNo', this.whNo);
			// 	this.filterData.whNo = this.whNo;
			// 	this.initData();
			// },
			// openTime(to, from) {
			// 	console.log('openTime', this.whNo);
			// 	this.filterData.whNo = this.whNo;
			// 	this.initData();
			// }
		},
		// watch: {
		// 	'whNo': {
		// 		handler(newVal) {
		// 			console.log('whNo', this.whNo);
		// 			this.filterData.whNo = this.whNo;
		// 			this.initData();
		// 		},
		// 		deep: true,
		// 		immediate: true,
		// 	}
		// },

		activated() {
			// if (this.$route.query && this.$route.query.plNo) {
			// 	this.filterData.plNo = this.$route.query.plNo;
			// }
			this.initData();
		},
		//创建时
		created() {
			console.log('created');
			// if (this.$route.query && this.$route.query.plNo) {
			// 	this.filterData.plNo = this.$route.query.plNo;
			// }
			// this.initData();
		},
		//编译挂载前
		mounted() {
			console.log('mounted');
			// if (this.whNo) {
			// 	this.filterData.whNo = this.whNo;
			// 	this.initData();
			// }
			// this.getDicData();
			//数据字典
			getDicData(['wh_place_type'],
				(data)=>{
					this.selectOption.wh_place_type = data['wh_place_type'];
			});
			
			this.getWhNoData();
		},
		methods: {
			initData() {
				//跳转到第一页
				// this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				this.tableData = [];
				this.treeData = [];
				if (!this.filterData.whNo) {
					this.$message.warning(this.$t('i18nn_1118edcaeb4c3679'));
					return;
				}
				this.getPageData();
				// 部门数据
				// this.getProvinceData();
				// this.getDetData("");
			},

			//选择仓库
			// changeDataWhNo(data) {
			// 	console.log('changeDataWhNo', data);
			// 	this.form.whNo = data.code;
			// },


			//作为组件选择行数据
			// selRow(event, row, index) {
			// 	event.stopPropagation();
			// 	this.$emit('selectRow', row);
			// },
			// selMulti() {
			// 	// event.stopPropagation();
			// 	if (this.multipleSelection.length <= 0) {
			// 		this.$message.warning(this.$t('i18nn_407c59bcef554efa'));
			// 		return;
			// 	}
			// 	this.$emit('selectMulti', this.multipleSelection);
			// },
			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				// this.multipleSelection = val;
			},

			//导入
			// openImportAction() {
			// 	this.importOpentTime = new Date().getTime();
			// },
			//分页的筛选项数据
			pageFilterData() {
				return {
					sortAsc: this.filterData.sortAsc,
					orderBy: this.filterData.orderBy,

					whNo: this.filterData.whNo ? this.filterData.whNo : null,
					place: this.filterData.place ? this.filterData.place : null,
					pArea: this.filterData.pArea ? this.filterData.pArea : null,
					keyword: this.filterData.keyword ? this.filterData.keyword : null,
					placeType: this.filterData.wh_place_type ? this.filterData.wh_place_type : null
				};
			},

			//请求分页数据
			getPageData() {
				this.loading_load = true;
				// console.log('filterData1',this.pageFilterData());
				let filterData = Object.assign({
						offset: 0, //当前页
						limit: 10000 //当前页显示数目
					},
					this.pageFilterData()
				);

				// console.log('filterData2',filterData);
				this.$http
					.put(this.$urlConfig.WhPlacePageList, filterData)
					.then(({
						data
					}) => {
						console.log('列表，请求成功');
						console.log(data);
						
						this.loading_load = false;
						if (200 == data.code) {
							//表格显示数据

							let tableData = data.rows;
							this.tableData = tableData;

							let treeData = [];
							// children
							// name
							// value
							// tableData.forEach((item,index)=>{
							// 	let children = [];

							// 	if(item.pArea){
							// 		item.pArea
							// 	}


							// 	let o = {
							// 		name:item.pArea,
							// 		value:item.pArea,
							// 		children:children
							// 	}
							// 	treeData.push(o);
							// });
							let pAreaList = [];
							let o = {
								children: []
							}

							for (let i = 0, ln = tableData.length; i < ln; i++) {
								let item = tableData[i];
								let children = [];

								// console.log('pAreaList',pAreaList,item.pArea);
								if (pAreaList.includes(item.pArea)) { //包含该区域
									treeData.forEach(item2 => { //区域排序无用，用此对比


										if (item2.name == item.pArea) {

											// let placeHasNum = 0;
											// let allNum = 0;
											if (item.placeStockTotal && item.placeStockTotal.totalStock && 0 != item.placeStockTotal
												.totalStock) {
												item2.placeHasNum++;
												item2.allNum = parseInt(item.placeStockTotal.totalStock) + item2.allNum;
											}

											// item2.value++;
											item2.children.push({
												name: item.place,
												// value:1,
												placeType: item.placeType,
												placeStockTotal: item.placeStockTotal ? item.placeStockTotal.totalStock : null,
											});
										}
									})
									// o.children.push({
									// 	name:item.pArea,
									// 	value:item.place,
									// });
								} else { //不包含该区域，添加进入树
									// let o = {
									// 	name:item.pArea,
									// 	value:item.pArea,
									// 	children:children
									// }
									let placeHasNum = 0;
									let allNum = 0;
									if (item.placeStockTotal && item.placeStockTotal.totalStock && 0 != item.placeStockTotal
										.totalStock) {
										placeHasNum++;
										allNum = parseInt(item.placeStockTotal.totalStock);
									}
									o = {
										name: item.pArea,
										placeHasNum: placeHasNum,
										allNum: allNum,
										// showPlace:"1",
										// value:1,
										children: [{
											name: item.place,
											// value:1,
											placeType: item.placeType,
											placeStockTotal: item.placeStockTotal ? item.placeStockTotal.totalStock : null,
										}]
									}
									treeData.push(o);
									pAreaList.push(item.pArea);
									// children = [];
									// children.push({
									// 	name:item.pArea,
									// 	value:item.pArea,
									// });
								}
							}

							// console.log('treeData',JSON.stringify(treeData));
							console.log('treeData', treeData);
							// treeData.sort();
							treeData.sort((a, b) => {
								return a.name > b.name;
							});
							treeData.forEach(item => {
								if (item.children && item.children.length > 0) {
									item.children.sort((a, b) => {
										return a.name > b.name;
									});
								}
							});
							// treeData.sort(function (obj1, obj2) {    
							//     // if (obj1.name == obj2.name) {
							//     //     return obj1.name > obj2.name;
							//     // }
							//     return obj1.name > obj2.name;
							// });

							this.treeData = treeData;
							//取消编辑时调用
							//缓存，待编辑的数据
							// this.tableDataCatch = data.rows;//直接赋值为浅拷贝 concat也是浅拷贝
							// this.tableDataCatch = deepClone(data.rows);
							//当前数据总条数
							this.pagination.total = parseInt(data.total);
							//当前页数
							// this.pagination.current_page = parseInt(data.current);
							//当前页条数
							// this.pagination.page_size = parseInt(data.size);
							// this.$message.success('订单列表，请求成功');
							this.ChartsOpenTime = new Date().getTime();
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a1761c0e88af75ff'));
						}
					})
					.catch(error => {
						console.log(error);
						console.log('列表，请求失败');
						this.$message.error(this.$t('hytxs0000030'));
						this.loading_load = false;
					});
			},

			//提交信息
			postData(url, formData, callBack) {
				// let _this = this;
				this.loading = true;
				// formData.state = formData.state ? '1' : '0';

				this.$http
					.put(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						
						this.loading = false;
						if (200 == data.code) {
							// this.dialogFormVisible = false;
							// this.dialogAddFormVisible = false;
							// this.initData();
							callBack();
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('i18nn_a7d2e953195a5588'));
						this.loading = false;
						this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},
			//查询仓库
			getWhNoData() {
				this.$http
					.put(this.$urlConfig.whNoPageList, {})
					.then(({
						data
					}) => {
						console.log('查询，请求成功');
						console.log(data);
						if (200 == data.code && data.rows) {
							// this.selectOption.wh_no = data.rows;
							let list = data.rows;
							this.selectOption.wh_no = list.map(item => {
								return {
									code: item.whNo,
									codeText: `${item.whName}(${item.address}.${item.city},${item.state} ${item.postalCode})`,
									codeTextEn: `${item.whNameEn}(${item.address}.${item.city},${item.state} ${item.postalCode})`
								}
							});
						} else {
							if (!data.msg) {
								data.msg = '查询仓库失败,请重试';
							}
							this.$message.warning(data.msg);
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('i18nn_323ee425eca7208c'));
						this.$message.error('查询仓库失败,请重试！');
					});
			},
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_place_type'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				// this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			// 				// this.selectOption.wh_car_tms_type = data.data['wh_car_tms_type'];
			// 				// this.selectOption.wh_no = data.data['wh_no'];
			// 				this.selectOption.wh_place_type = data.data['wh_place_type'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('hytxs0000032');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('hytxs0000033'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// /deep/ .el-table__body {
	// 	.el-input {
	// 		input {
	// 			padding-left: 5px;
	// 			padding-right: 5px;
	// 		}
	// 	}

	// 	.el-input-number {
	// 		width: 90px;

	// 		.el-input__inner {
	// 			text-align: left;
	// 		}
	// 	}
	// }
</style>